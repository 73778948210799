import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Rodzaje = () => {
  return (
    <div id="rodzaje" className="kompensacjaElement">
      <StaticImage
        src="../../assets/images/kompensacja/rodzaje.png"
        alt="rodzaje_tlo"
        placeholder="tracedSVG"
        className="elementBackground"
      />
      <div className="text-holder">
        <h3>WYSTĘPUJĄ DWA RODZAJE MOCY BIERNEJ:</h3>
        <p>
          - BIERNA INDUKCYJNA – niezbędna do wytwarzania pola magnetycznego w
          transformatorach czy silnikach. <br />
          <br /> - BIERNA POJEMNOŚCIOWA – oddawana do sieci elektroenergetycznej
          przez odbiorniki o charakterze pojemnościowym – oświetlenie LED,
          podtrzymanie zasilania UPS, kondensatory itp.
        </p>
      </div>
    </div>
  )
}

export default Rodzaje
