import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Oferujemy = () => {
  return (
    <div id="oferujemy" className="kompensacjaElement">
      <StaticImage
        src="../../assets/images/kompensacja/oferujemy.png"
        alt="oferujemy_tlo"
        placeholder="tracedSVG"
        className="elementBackground"
      />
      <div className="text-holder">
        <h3>OFERUJEMY</h3>
        <p>
          Nasz wykwalifikowany zespół pomoże prawidłowo dobrać urządzenie
          kompensujące moc bierną, aby zapewnić obniżenie rachunków za prąd.
          Oferujemy także serwis, który zapewni bezawaryjną pracę urządzeń przez
          lata.
        </p>
      </div>
    </div>
  )
}

export default Oferujemy
