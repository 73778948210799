import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "../../assets/css/kompensacja.css"

const KompMoBie = () => {
  return (
    <div id="kompensacja" className="kompensacjaElement">
      <StaticImage
        src="../../assets/images/kompensacja/kompensacja.png"
        alt="kompensacja_tlo"
        placeholder="tracedSVG"
        className="elementBackground"
      />
      <div className="text-holder">
        <h3>KOMPENSACJA MOCY BIERNEJ</h3>
        <p>
          Kompensatory mocy biernej znacząco pomagają w zoptymalizowaniu zużycia
          energii elektrycznej. Coraz częściej sprzedawca energii nalicza
          dodatkowe opłaty za przekroczenie dopuszczalnej mocy biernej odbiorcom
          instytucjonalnym. Eliminując problem większego poboru mocy biernej,
          dzięki zamontowaniu kompensatora, obniżamy swoje rachunki za prąd!
          Dodatkowo wykorzystanie urządzeń kompensacyjnych powoduje zmniejszenie
          zużycia energii czynnej
        </p>
      </div>
    </div>
  )
}

export default KompMoBie
