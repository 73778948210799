import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
//My Components
import Top from "../components/Top"
import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer"
import Seo from "../components/Seo"
import Rodzaje from "../components/kompensacja/Rodzaje"
import Oferujemy from "../components/kompensacja/Oferujemy"
import KompMoBie from "../components/kompensacja/KompMoBie"
import SideMenu from "../components/SideMenu"

// Query for Top image and Contact Form background image
export const query = graphql`
  {
    form: file(
      name: { eq: "kompensacja" }
      sourceInstanceName: { eq: "formBackgrounds" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
    top: file(
      name: { eq: "kompensacja" }
      sourceInstanceName: { eq: "topImages" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
  }
`

const Kompensacja = () => {
  const data = useStaticQuery(query)
  const imgForm = getImage(data.form)
  const imgTop = getImage(data.top)
  return (
    <div id="kompensacja" className="page kompensacjaPage">
      <Seo title="Kompensacja mocy biernej" />
      <Top image={imgTop} />
      <KompMoBie />
      <Rodzaje />
      <Oferujemy />
      <ContactForm backgroundImage={imgForm} nrTel="781 144 999" />
      <Footer nrTel="781 144 999" email="oze@eka.pl" />
      <SideMenu base="/kompensacja#komepnsacja">
        <Link className="sideMenuOZEBack" to="/ekologiczna_energia">
          <StaticImage
            src="../assets/images/oze/oze_back.png"
            alt="sterowanie"
            placeholder="tracedSVG"
          />
        </Link>
      </SideMenu>
    </div>
  )
}

export default Kompensacja
